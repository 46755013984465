
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.App-link {
  color: #61dafb;
}



.bmiBTN{
  cursor: pointer;
  box-shadow: 5px 5px 5px 0px black;
  color: white;
  background-color: black;
}

.bjjBTN{
  color: red;
  cursor: pointer;
  box-shadow: 5px 5px 5px 0px black;
  background-color: black;

}